<template>
    <div class="project-card">
        <div class="project-card__image-box">
            <img :src="`/images/projects/${project.image}`" :alt="project.name" class="project-card__image" />
        </div>
        <div class="project-card__content-box">
            <h3 class="heading">{{ project.name }}</h3>
            <div class="project-card__content">{{ project.text }}</div>
            <div class="project-card__links">
                <template v-for="(link, i) of project.links" :key="i">
                    <a :href="link.href" class="project-card__link" target="_blank" @click="linkEvent(project)">
                        {{ link.text }}
                    </a>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProjectCard',
        props: ['project'],
        methods: {
            linkEvent(project) {
                this.$gtm.trackEvent({
                    event: 'project_link_click',
                    category: 'Projects',
                    action: 'click',
                    event_trigger: project.event,
                    project_name: project.name,
                });
            },
        },
    };
</script>
