<template>
    <div class="header" background="../assets/images/rotterdam-1.jpg">
        <h1 class="header__title">Bas<span class="header__dot">.</span></h1>
        <div class="header__scroll" v-if="scrollDepth < 15"></div>
    </div>
</template>

<script>
    export default {
        name: 'HeaderComponent',
        data() {
            return {
                scrollDepth: 0,
            };
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll() {
                this.scrollDepth = window.pageYOffset;
            },
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.handleScroll);
        },
    };
</script>
