export default [
    {
        tech: 'HTML5',
        iconClass: 'fab fa-html5',
    },
    {
        tech: 'CSS3',
        iconClass: 'fab fa-css3-alt',
    },
    {
        tech: 'JavaScript',
        iconClass: 'fab fa-js',
    },
    {
        tech: 'Sass',
        iconClass: 'fab fa-sass',
    },
    {
        tech: 'NodeJS',
        iconClass: 'fab fa-node-js',
    },
    {
        tech: 'React',
        iconClass: 'fab fa-react',
    },
    {
        tech: 'Vue',
        iconClass: 'fab fa-vuejs',
    },
    {
        tech: 'PHP',
        iconClass: 'fab fa-php',
    },
    {
        tech: 'Git',
        iconClass: 'icon-git',
    },
    {
        tech: 'GitHub',
        iconClass: 'fab fa-github',
    },
    {
        tech: 'NPM',
        iconClass: 'fab fa-npm',
    },
    {
        tech: 'MongoDB',
        iconClass: 'icon-mongodb',
    },
    {
        tech: 'PostgresQL',
        iconClass: 'icon-postgres',
    },
    {
        tech: 'MySQL',
        iconClass: 'icon-mysql',
    },
];
