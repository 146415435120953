<template>
    <header-component />
    <about-component />
    <projects-component />
    <experience-component />
    <footer-component />
</template>

<script>
    import HeaderComponent from '@/components/HeaderTop.vue';
    import AboutComponent from '@/components/About.vue';
    import ProjectsComponent from '@/components/Projects.vue';
    import ExperienceComponent from '@/components/Experience.vue';
    import FooterComponent from '@/components/Footer.vue';

    export default {
        data() {
            return {};
        },
        components: {
            HeaderComponent,
            AboutComponent,
            ProjectsComponent,
            ExperienceComponent,
            FooterComponent,
        },
    };
</script>
