<template>
    <div class="section-projects">
        <h2 class="projects__heading">Projects</h2>
        <div class="projects">
            <template v-for="(project, i) of projects" :key="i">
                <project-card :project="project" />
            </template>
        </div>
    </div>
</template>

<script>
    import ProjectCard from '@/components/ProjectCard.vue';
    import projects from '@/config/projects';

    export default {
        name: 'ProjectsComponent',
        components: { ProjectCard },
        data() {
            return {
                projects: projects,
            };
        },
    };
</script>
