<template>
    <div>
        <div class="exp-button__container">
            <div class="exp-button__button">
                <i :class="item.iconClass" />
            </div>
            <span class="exp-button__subtext">{{ item.tech }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ExperienceButton',
        props: ['item'],
    };
</script>
