<template>
    <div class="section-experience">
        <h2 class="heading">Experience</h2>
        <div class="experience">
            <template v-for="(item, i) of exp" :key="i">
                <experience-button :item="item" />
            </template>
        </div>
    </div>
</template>

<script>
    import ExperienceButton from '@/components/ExperienceButton.vue';
    import experience from '@/config/experience';

    export default {
        name: 'ExperienceComponent',
        components: {
            ExperienceButton,
        },
        data() {
            return {
                exp: experience,
            };
        },
    };
</script>
