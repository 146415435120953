<template>
    <div class="about">
        <div class="about__text">
            <h2 class="heading">About Me</h2>
            <p>
                The first website I ever built, at 12 years old, was of course on Geocities. The efforts were thoroughly
                enjoyable, though fairly embarrassing, and tied up the phoneline something fierce.
            </p>
            <p>
                A lot has changed since then and I've dived back into web development. Including the back-end stuff this
                time. It's still as enjoyable as it ever was. There's just many more possibilities
            </p>
        </div>
        <div>
            <img src="../assets/images/profielfoto-small.jpeg" alt="Profielfoto Bas" class="about__image" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutComponent',
        data() {
            return {};
        },
    };
</script>
