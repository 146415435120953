import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createGtm } from '@gtm-support/vue-gtm';
import './css/index.scss';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(
    createGtm({
        id: 'GTM-MCFHFHZ',
    })
);

app.mount('#app');
