export default [
    {
        name: 'Advent of Code',
        image: 'advent-small.jpg',
        text: 'A yearly series of coding challenges. Solutions written in Javascript / Node. Links to repository on GitHub.',
        links: [{ text: 'Github', href: 'https://github.com/BasKalkman/advent-of-code' }],
        event: 'AoC_clicked'
    },
    {
        name: 'Todo List',
        image: 'todolist.jpg',
        text: 'Because, of course there is...',
        links: [{ text: 'Demo', href: 'https://todo.baskalk.nl' }],
        event: 'Todo_clicked',
    },
    {
        name: 'SCSS course',
        image: 'trillo-small.jpg',
        text: 'Fictional company pages created as part of a (S)CSS course.',
        links: [
            { text: 'Trillo', href: 'https://demo.baskalk.nl/trillo' },
            { text: 'Natours', href: 'https://demo.baskalk.nl/natours' },
        ],
        event: 'SCSS_project_clicked'
    },
    {
        name: 'Customer portal',
        image: 'mijnps-small.jpg',
        text: 'Customer portal for matchmakinging agency. Customer can manage their own profile and respond to message and introductions. Built with VueJS',
        links: [],
        event: 'MijnPS_clicked',
    },
    {
        name: 'Color Guesser',
        image: 'color-guesser-small.jpg',
        text: 'One of the first games made as part of a Udemy course. The Webdeveloper Bootcamp.',
        links: [
            { text: 'Demo', href: 'https://demo.baskalk.nl/color-guesser' },
            { text: 'Github', href: 'https://github.com/BasKalkman/colorguesser' },
        ],
        event: 'ColorGuesser_clicked',
    },
    {
        name: 'Color Picker',
        image: 'color-picker-small.jpg',
        text: 'A riff on the Color Guesser project, mixed with an Android app store game. Created to put exercises from a previous course into practice.',
        links: [
            { text: 'Demo', href: 'https://demo.baskalk.nl/color-picker' },
            { text: 'Github', href: 'https://github.com/BasKalkman/color-picker' },
        ],
        event: 'ColorPicker_clicked',
    },
    {
        name: 'Memory',
        image: 'memory-small.jpg',
        text: 'A small, though very difficult, game of memory. Started just to practice the card flip effect, but grew into a bit more.',
        links: [
            { text: 'Demo', href: 'https://demo.baskalk.nl/memory' },
            { text: 'Github', href: 'https://github.com/BasKalkman/Memory' },
        ],
        event: 'Memory_clicked'
    },
];
